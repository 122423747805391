import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
  Alert,
} from "@mui/material";
import PasswordFormControl from "./components/PasswordFormControl";
import axios from "axios";
import { NGROK } from "../../../APIs";
import moment from "moment";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";

const CredentialsMachine = ({ selectedTenant, latestComputer }) => {
  const [open, setOpen] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [labelErrorMessage, setLabelErrorMessage] = useState("");
  const [label, setLabel] = useState("");
  const { role, email } = useUserStore((state) => state.user);
  const userData = useUserStore((state) => state.user);
  const [usersList, setUsersList] = useState([]);
  const [usernameError, setUsernameError] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  selectedTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setLabel("");
    setUsername("");
    setPassword("");
    setLabelErrorMessage("");
    setUsernameError("");
    setOpen(false);
  };

  const isDeviceWindows = (device) => {
    if (device.OperatingSystem?.trim().length > 0) {
      return device.OperatingSystem.toLowerCase().includes("windows");
    } else {
      return true;
    }
  };

  const handleLabelInput = (value) => {
    setLabelErrorMessage("");
    if (value.includes("_")) return;
    else setLabel(value);
  };

  const postNewCredential = async () => {
    try {
      if (selectedUserId === null) return;
      const response = await axios.post(`${NGROK}/api/credentials`, {
        label,
        username,
        password,
        retypePassword: password,
        tenantName: selectedTenant,
        os: "WINDOWS",
        computerId: latestComputer?.id,
        createdBy: email,
        userId: selectedUserId,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    const newCred = await postNewCredential();
    getCredentialsDetails();
    handleClose();
  };

  const getCredentialsDetails = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenant}/credentials?computerId=${latestComputer?.id}`,
      );
      if (response?.data) {
        setCredentials(response?.data);
      }
    } catch (e) {}
  };

  const handleDeleteCredentials = async (label) => {
    const response = await axios.delete(`${NGROK}/api/credentials/${label}`);
    if (response?.data) {
      getCredentialsDetails();
    }
  };

  const isSubmitBtnEnabled = label && password && username && !usernameError;

  const getUsers = async () => {
    if (
      userData.role === "TENANT_ADMIN" ||
      (userData.role === "SYS_ADMIN" && isDeviceWindows(latestComputer))
    ) {
      axios
        .get(
          `${NGROK}/api/${selectedTenant}/computer-users/${latestComputer?.id}`,
        )
        .then((res) => {
          let content = res.data.content;
          const uniqueUsers = [];
          const seenEmails = new Set();

          for (const user of content) {
            if (!seenEmails.has(user.email)) {
              seenEmails.add(user.email);
              uniqueUsers.push(user);
            }
          }

          setUsersList(uniqueUsers);
        });
    }
  };

  useEffect(() => {
    getCredentialsDetails();
    getUsers();
  }, [latestComputer?.id]);

  const checkUsernameAvailability = (username) => {
    const isUsernameTaken = credentials.some(
      (cred) => cred.credentialUsername === username,
    );
    const isValidUser = usersList.some((user) => user.cn === username);

    if (isUsernameTaken) {
      setUsernameError("Username already exists in credentials.");
      return false;
    } else if (!isValidUser) {
      setUsernameError("Local user not found.");
      return false;
    } else {
      const user = usersList.find((user) => user.cn === username);
      setUsernameError("");
      setSelectedUserId(user?.id);
      return true;
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);

    checkUsernameAvailability(value);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        style={{ float: "right", marginBottom: 16 }}
      >
        Add New Credential
      </Button>

      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            maxWidth: "100%",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "16px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Created By</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {credentials.length > 0 ? (
              credentials.map((cred, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {cred?.credentialUsername}
                  </TableCell>
                  <TableCell align="center">
                    {moment(cred?.createdDate || new Date()).format(
                      "DD/MM/YYYY",
                    )}
                  </TableCell>
                  <TableCell align="center">{cred?.createdBy || "-"}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() =>
                        handleDeleteCredentials(cred?.credentialLabel)
                      }
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <Typography variant="h4">No Credential found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Credential</DialogTitle>
        <DialogContent>
          <Stack direction={"column"} spacing={5} sx={{ mt: 2 }}>
            <TextField
              label={"Credential label"}
              autoFocus
              margin="dense"
              type="text"
              inputProps={{ maxLength: 250 }}
              value={label}
              onChange={(e) => handleLabelInput(e.target.value)}
            />
            {labelErrorMessage ? (
              <Alert sx={{ marginTop: 1 }} severity="error">
                {labelErrorMessage}
              </Alert>
            ) : null}
            <TextField
              label={"Username"}
              inputProps={{ maxLength: 250 }}
              type="text"
              margin="dense"
              value={username}
              onChange={handleUsernameChange}
              error={!!usernameError}
              helperText={usernameError}
            />
            <PasswordFormControl
              password={password}
              setPassword={setPassword}
              label={"Password"}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            disabled={!isSubmitBtnEnabled}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CredentialsMachine;
