import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
  Chip,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import Devicelist from "./Devicelist";
import ResourceRequestForm from "../../../components/ResourceRequestForm";
import { myLocalStorage } from "../../../../components/StorageHelper";
import useUserStore from "../../../../services/userStore";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import ConfigureConnectionModal from "./ConfigureConnectionModal";
import PublishPopover from "./PublishPopover";

const EndpointsTableBody = ({
  getComputers,
  deviceList,
  updateDeviceProfile,
  updateDeviceOperationMode,
  handleConnectButton,
  toggleRecording,
  profiles,
  fetchDataProfiles,
  setProfiles,
  selectedTenant,
  upgradeMachine,
  checkIfMachineSelected,
  handleDeviceSelect,
  updateManageComputer,
  adminAccess,
  userRole,
  userRoleList,
  openAuthDetailsModal,
  requestFormOpened,
  setRequestFormOpened,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const setActiveComputer = useUserStore((state) => state.setActiveComputer);
  const { role, userId, email } = useUserStore((state) => state.user);
  const setComputerId = useUserStore((state) => state.setComputerId);
  const [showAllDevices, setShowAllDevices] = useState({});
  const [checkedItems, setCheckedItems] = useState([]);
  const handlePublishClick = (event, rolesList) => {
    setAnchorEl(event.currentTarget);
    setCheckedItems(rolesList ? rolesList : []);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    console.log(value, checkedItems);
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems(newChecked);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const ipToShow = (device) =>
    showAllDevices[device.id]
      ? device.IPV4Addresses
      : device.IPV4Addresses.slice(0, 2);
  const hasMore = (device) => device.IPV4Addresses.length > 2;
  const isDeviceWindows = (device) => {
    if (device.OperatingSystem?.trim().length > 0) {
      return device.OperatingSystem.toLowerCase().includes("windows");
    } else {
      return true;
    }
  };
  const connectAccessDisable = (device) => {
    switch (adminAccess) {
      case "NO_ACCESS":
        return true;

      case "SERVER_ONLY":
        const os = device.OperatingSystem.toLowerCase();
        if (device?.isManuallyAddedMachine) {
          return false;
        } else if (
          os.includes("linux") ||
          (os.includes("windows") && os.includes("server"))
        ) {
          return false;
        } else {
          return true;
        }

      default:
        return false;
    }
  };

  const connectButtonEnabled = (device) => {
    const isEnabled = device.connectionCreatedFor?.some(
      (user) => userId === user.userId && role === user.role,
    );

    return isEnabled;
  };

  const getDeviceGroupName = (groups) => {
    if (!groups.length) return "-";
    const groupWithHighestRepScore = groups.reduce(
      (prev, current) =>
        prev.reputationScore > current.reputationScore ? prev : current,
      groups[0],
    );
    return groupWithHighestRepScore.categoryName;
  };

  const handleShowMoreClick = (deviceId) => {
    setShowAllDevices((prevState) => ({
      ...prevState,
      [deviceId]: !prevState[deviceId],
    }));
  };

  return (
    <TableBody>
      {deviceList?.map((device) => (
        <TableRow
          key={device.id}
          sx={{ border: 1 }}
          onClick={() => {
            setActiveComputer(device);
            myLocalStorage.setItem("activeComputer", device);
          }}
        >
          {role !== "TENANT_USER" ? (
            <TableCell sx={{ fontSize: "16px" }} align="center">
              <Checkbox
                checked={checkIfMachineSelected(device.id)}
                onChange={() => handleDeviceSelect(device)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </TableCell>
          ) : null}
          <TableCell sx={{ fontSize: "16px", padding: 0 }}>
            <Devicelist
              device={device}
              upgradeMachine={upgradeMachine}
              role={role}
              selectedTenant={selectedTenant}
              getComputers={getComputers}
              handleConnectButton={handleConnectButton}
            />
          </TableCell>
          {role !== "TENANT_USER" ? (
            <TableCell sx={{ fontSize: "16px" }} align="center">
              {!device.categories.length ? (
                "-"
              ) : (
                <Chip
                  sx={{
                    bgcolor: "#ebebeb",
                    color: "000",
                  }}
                  label={getDeviceGroupName(device.categories)}
                />
              )}
            </TableCell>
          ) : null}
          {role !== "TENANT_USER" ? (
            <TableCell sx={{ fontSize: "16px" }} align="center">
              <Stack spacing={2}>
                {ipToShow(device).length === 0 ? (
                  "-"
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      maxWidth: 200,
                      overflowWrap: "break-word",
                      textAlign: "left",
                    }}
                  >
                    {ipToShow(device).join(", ")}
                  </Typography>
                )}
                {!showAllDevices[device.id] && hasMore(device) && (
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      textAlign: "left",
                    }}
                    onClick={() => handleShowMoreClick(device.id)}
                  >
                    Show More
                  </Typography>
                )}
              </Stack>
            </TableCell>
          ) : null}
          <TableCell sx={{ fontSize: "16px" }} align="center">
            {device?.isManuallyAddedMachine ? (
              <Typography variant="body2">Not Applicable</Typography>
            ) : (
              <FormControl fullWidth>
                <Select
                  disabled={role === "TENANT_USER"}
                  sx={{ minWidth: "100px" }}
                  onOpen={() => fetchDataProfiles(device.id)}
                  onClose={() => setProfiles([])}
                  labelId="device-profile-select-label"
                  id="device-profile-select"
                  value={device.profile || " "}
                  size="small"
                  onChange={(event) => updateDeviceProfile(event, device)}
                >
                  {profiles?.map(
                    (profile) =>
                      profile.name !== device.profile && (
                        <MenuItem value={profile.name} key={profile.id}>
                          {profile.name || "Select profile"}
                        </MenuItem>
                      ),
                  )}
                  <MenuItem value={device.profile}>
                    {device.profileIsOverwrittenByCategory
                      ? `INHERITED (${device.profile})`
                      : device.profile}
                  </MenuItem>
                  {!profiles && <MenuItem value="">Loading...</MenuItem>}
                </Select>
              </FormControl>
            )}
          </TableCell>
          <TableCell sx={{ fontSize: "16px" }} align="center">
            {device?.isManuallyAddedMachine ? (
              <Typography variant="body2">Not Applicable</Typography>
            ) : (
              <Select
                disabled={role === "TENANT_USER"}
                sx={{ minWidth: "100px" }}
                labelId="device-mode-select-label"
                id="device-mode-select"
                value={device.mode || " "}
                size="small"
                onChange={(event) => updateDeviceOperationMode(event, device)}
              >
                <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
                <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
                <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
                <MenuItem value={"INHERITED_FROM_PROFILE"}>
                  INHERITED{" "}
                  {`(${
                    device.profileMode !== "INHERITED_FROM_TENANT"
                      ? device.profileMode
                      : selectedTenant.mode
                  })`}
                </MenuItem>
              </Select>
            )}
          </TableCell>
          {role !== "TENANT_USER" ? (
            <>
              <TableCell sx={{ fontSize: "16px" }} align="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PublishPopover
                    device={device}
                    updateManageComputer={updateManageComputer}
                    userRoleList={userRoleList}
                  />

                  {/* <Switch
                    checked={device.isManageComputer}
                    onChange={() => updateManageComputer(device.id)}
                  />
                  <Typography variant="body2">Publish</Typography>{" "} */}
                </Box>
              </TableCell>
            </>
          ) : null}

          <TableCell sx={{ fontSize: "16px" }} align="center">
            {userRole === "epam-user" ||
            (userRole === "server-pam-user" &&
              device.belongedToUser === email) ||
            (userRole === "developer-user" &&
              device.belongedToUser === email) ? (
              "-"
            ) : (
              <Stack
                spacing={2}
                direction="column"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {role !== "TENANT_USER" ||
                (role === "TENANT_USER" && device.hasAccess) ? (
                  <>
                    <Stack
                      direction={"row"}
                      sx={{
                        display: "flex",
                        justifyContent:
                          role === "TENANT_USER" ? "center" : "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                      spacing={2}
                    >
                      {isDeviceWindows(device) && role !== "TENANT_USER" ? (
                        <Button
                          variant="outlined"
                          onClick={() => openAuthDetailsModal(device)}
                          sx={{
                            width: "150px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Connect
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          disabled={
                            connectAccessDisable(device) ||
                            (!isDeviceWindows(device) &&
                              !connectButtonEnabled(device) &&
                              !device?.isManuallyAddedMachine)
                          }
                          onClick={() => handleConnectButton(device)}
                          sx={{
                            width: "150px",
                          }}
                        >
                          Connect
                        </Button>
                      )}

                      {!isDeviceWindows(device)
                        ? device?.OperatingSystem?.trim().length > 0 &&
                          !device?.isManuallyAddedMachine &&
                          role !== "TENANT_USER" && (
                            <Box sx={{ cursor: "pointer" }}>
                              <ConfigureConnectionModal
                                getComputers={getComputers}
                                device={device}
                                tenantName={selectedTenant?.tenantName}
                              />
                            </Box>
                          )
                        : null}
                    </Stack>
                  </>
                ) : (
                  <ResourceRequestForm
                    tenantName={selectedTenant.tenantName}
                    resourceName="Whiteswan Access"
                    resourcePath="Whiteswan Accesss"
                    sessionRequest={true}
                    resourceType="GROUP"
                    computer={device}
                    resourceId={device.whiteswanAccessGroupId}
                    isWhiteswanAccess={device.isWhiteswanAccess}
                    isFromEndPoint={true}
                    handleConnectButton={handleConnectButton}
                    requestFormOpened={requestFormOpened}
                    setRequestFormOpened={setRequestFormOpened}
                  />
                )}

                {role !== "TENANT_USER" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      checked={device.recordingPathEnabled}
                      onChange={() => toggleRecording(device)}
                    />
                    <Typography variant="body2">Recording</Typography>{" "}
                  </Box>
                )}
              </Stack>
            )}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default EndpointsTableBody;
