import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Tooltip,
  List,
  ListItem,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Stack,
  Button,
} from "@mui/material";
import AccessAWSGroups from "./components/AccessAWSGroups";
import AccessAWSPolicies from "./components/AccessAWSPolicies";
import AccessAWSResources from "./components/AccessAWSResources";
import { awsIcons } from "./AWSIcons";
import moment from "moment";
import { myLocalStorage } from "../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../APIs";
import SyncIcon from "@mui/icons-material/Sync";
import useUserStore from "../../../services/userStore";
import AWSRequestResourceAccessForm from "./components/AWSRequestResourceAccessForm";
import useNotificationsStore from "../../../services/notificationStore";
import LoginToAWS from "./LoginToAws";
const AWSUserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { role, email } = useUserStore((state) => state.user);
  const { userAWSNotifications, adminawsNotifications } =
    useNotificationsStore();
  const [onAWSRequestResourceAccessForm, setOnAWSRequestResourceAccessForm] =
    useState(false);
  const [generateTemporaryPassword, setGenerateTemporaryPassword] =
    useState(false);
  const { user: initialUser } = location.state || { user: {} };
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [selectedAccess, setSelectedAccess] = useState("resources");
  const [user, setUser] = useState(initialUser);
  const [groups, setGroups] = useState(initialUser?.groups || []);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncInitial, setSyncInitial] = useState(false);
  const { username, arn, createDate, userPolicies = [], userId } = user;

  const consolidatePolicies = () => {
    const userPolicyNames =
      userPolicies?.map((policy) => policy?.policyName) || [];
    const groupPolicyNames =
      groups?.flatMap((group) =>
        group?.groupPolicies?.map((policy) => policy?.policyName),
      ) || [];

    return [...userPolicyNames, ...groupPolicyNames];
  };
  const consolidatedPolicies = consolidatePolicies();

  const getDisplayItems = (items) => items.slice(0, 2);
  const getMoreCount = (items) => items.length - 2;

  const getAllDetails = (val) => {
    setSelectedAccess(val);
  };

  const getSpecificUsers = async (arn) => {
    const response = await axios.get(
      `${NGROK}/api/aws/getSpecificUser?policyarn=${arn}&tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const onDemand = async (arn) => {
    const response = await axios.post(
      `${NGROK}/api/aws/ondemandSpecificUsers?tenantName=${latestTenant}`,
    );
  };

  const fetchAWSUserConfiguration = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getAWSUserConfig?tenantName=${latestTenant}&tenantEmail=${email}`,
    );
    if (response?.data) {
      let { awsAccountARN, awsUserName } = response?.data;
      if (awsAccountARN == null ? true : false) {
        navigate(-1);
      } else {
        myLocalStorage.setItem("userDetails", JSON.stringify(response?.data));
      }
    } else {
      navigate(-1);
    }
  };

  const raiseConsoleAccess = () => {
    setOnAWSRequestResourceAccessForm(true);
  };

  const consoleLevelAccess = userAWSNotifications?.filter(
    (userAWSNotification) =>
      userAWSNotification.accessType === "console" &&
      userAWSNotification.requestStatus === "APPROVED",
  );
  const hasConsoleAccess = consoleLevelAccess?.length > 0;
  const openAWSLogin = () => {
    setGenerateTemporaryPassword(true);
  };
  const handleSync = async () => {
    const userDetails = myLocalStorage.getItem("userDetails");
    const userarn = arn || JSON.parse(userDetails).awsAccountARN;
    setIsSyncing(true);
    await onDemand();
    await fetchAWSUserConfiguration();
    const userResponse = await getSpecificUsers(userarn);

    setUser(userResponse);
    setGroups(userResponse?.groups !== null ? userResponse?.groups : []);

    setIsSyncing(false);
    setSyncInitial(false);
  };

  useEffect(() => {
    const isconfigured = location.state?.isconfigured || false;
    if (isconfigured && !syncInitial) {
      setSyncInitial(true);
      handleSync();
    }
  }, [location.state]);
  return (
    <>
      <Box sx={{ padding: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mb: 2 }}>
          <Stack direction={"row"} spacing={5}>
            {role !== "TENANT_USER" ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSync}
                disabled={isSyncing}
              >
                {isSyncing ? "Syncing" : "Sync"}
              </Button>
            ) : null}
            {role === "TENANT_USER" ? (
              hasConsoleAccess ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openAWSLogin}
                >
                  Connect
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={raiseConsoleAccess}
                >
                  Request access
                </Button>
              )
            ) : null}
          </Stack>
        </Box>
        {syncInitial ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "65vh",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  fontSize: "2rem",
                  animation: "blinking 1.5s infinite",
                }}
              >
                <SyncIcon size="2rem" />
              </Box>
            </Box>
            <Typography sx={{ mt: 2, fontSize: "1.2rem", color: "gray" }}>
              Please wait. We are syncing initial user details
            </Typography>

            <style>
              {`
          @keyframes blinking {
            0% {
              opacity: 0.5;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0.5;
            }
          }
        `}
            </style>
          </Box>
        ) : (
          <Stack direction={"column"} spacing={5}>
            <Paper elevation={4} sx={{ padding: 3, borderRadius: 2 }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "18px" }}
              >
                {username}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", fontSize: "18px" }}
                  >
                    ARN:
                  </Typography>
                  <Typography variant="body1">{arn}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", fontSize: "18px" }}
                  >
                    User ID:
                  </Typography>
                  <Typography variant="body1">{userId}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", fontSize: "18px" }}
                  >
                    Created Date:
                  </Typography>
                  <Typography variant="body1">
                    {moment(createDate).format("DD/MM/YYYY HH:mm:ss")}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", fontSize: "18px" }}
                  >
                    Groups:
                  </Typography>
                  {groups?.length > 0 ? (
                    <>
                      {getDisplayItems(groups).map((group) => (
                        <Typography key={group.groupId} variant="body1">
                          {group.groupName}
                        </Typography>
                      ))}

                      {getMoreCount(groups) > 0 && (
                        <Tooltip
                          title={
                            <List>
                              {groups.map((group) => (
                                <ListItem key={group.groupId}>
                                  {group.groupName}
                                </ListItem>
                              ))}
                            </List>
                          }
                          placement="top"
                        >
                          <Typography variant="body1" color="primary">
                            +{getMoreCount(groups)} more
                          </Typography>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Typography variant="body1">-</Typography>
                  )}
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", fontSize: "18px" }}
                  >
                    Policies:
                  </Typography>
                  {consolidatedPolicies?.length > 0 ? (
                    getDisplayItems(consolidatedPolicies).map(
                      (policy, index) => (
                        <Typography key={index} variant="body1">
                          {policy}
                        </Typography>
                      ),
                    )
                  ) : (
                    <Typography variant="body1">-</Typography>
                  )}

                  {getMoreCount(consolidatedPolicies) > 0 && (
                    <Tooltip
                      title={
                        <List>
                          {consolidatedPolicies.map((policy, index) => (
                            <ListItem key={index}>{policy}</ListItem>
                          ))}
                        </List>
                      }
                      placement="top"
                    >
                      <Typography variant="body1" color="primary">
                        +{getMoreCount(consolidatedPolicies)} more
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Paper>

            <Stack direction={"row"} spacing={2}>
              <Box width={"30%"}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      height: "fit-content",
                      "& th": {
                        background: "#233044",
                        color: "#fff",
                      },
                      "& td, & th": {
                        border: "1px solid #233044",
                        fontSize: "18px",
                      },
                    }}
                    size="large"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Privileges</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        <TableRow
                          key={"resources"}
                          selected={selectedAccess === "resources"}
                          onClick={() => {
                            getAllDetails("resources");
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                            "& td, & th": {
                              border: "1px solid #233044",
                              fontSize: "18px",
                            },
                          }}
                        >
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              {awsIcons.resources}
                              <Box ml={2}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: "18px",
                                  }}
                                >
                                  Resources
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          selected={selectedAccess === "groups"}
                          key={"groups"}
                          onClick={() => {
                            getAllDetails("groups");
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                            "& td, & th": {
                              border: "1px solid #233044",
                              fontSize: "18px",
                            },
                          }}
                        >
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              {awsIcons.groups}
                              <Box ml={2}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: "18px",
                                  }}
                                >
                                  Groups
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {selectedAccess === "groups" ? (
                <AccessAWSGroups user={user} groups={groups} />
              ) : null}
              {selectedAccess === "policies" ? (
                <AccessAWSPolicies user={user} groups={groups} />
              ) : null}
              {selectedAccess === "resources" ? (
                <AccessAWSResources user={user} groups={groups} />
              ) : null}
            </Stack>
          </Stack>
        )}
      </Box>
      {onAWSRequestResourceAccessForm && (
        <AWSRequestResourceAccessForm
          open={onAWSRequestResourceAccessForm}
          onClose={() => {
            setOnAWSRequestResourceAccessForm(false);
          }}
          data={""}
          title={"Console Access"}
          user={user}
          arn={""}
          from={"console"}
        />
      )}
      {generateTemporaryPassword && hasConsoleAccess && user && latestTenant ? (
        <LoginToAWS
          setGenerateTemporaryPassword={setGenerateTemporaryPassword}
          generateTemporaryPassword={generateTemporaryPassword}
          tenantName={latestTenant}
          timing={15}
          userName={user?.username}
        />
      ) : null}
    </>
  );
};

export default AWSUserDetails;
