import React, { useEffect, useState } from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Tooltip,
  Box,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";
import { formatDateTime } from "../../../../GenericMethods";
import { GrUpgrade } from "react-icons/gr";
import { RxUpdate } from "react-icons/rx";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../../../services/userStore";
import RouterIcon from "@mui/icons-material/Router";
import { RiComputerLine } from "react-icons/ri";

const DevicelistItem = ({ device, upgradeMachine, role, selectedTenant }) => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const [upgrading, setUpgrading] = useState(false);
  const operatingSystems = {
    linux: "LINUX",
    windows: "WINDOWS",
    mac: "MAC",
  };
  const setActiveComputer = useUserStore((state) => state.setActiveComputer);

  const os = Object.keys(operatingSystems).find((key) => {
    if (device.OperatingSystem?.trim().length > 0) {
      return device.OperatingSystem.toLowerCase().includes(key);
    } else {
      return "linux";
    }
  });

  const osIcon = {
    linux: <FaLinux size={40} color={"#233044"} />,
    windows: <FaWindows size={35} color={"#233044"} />,
    mac: <FaApple size={40} color={"#233044"} />,
  };

  const getLastSeenStatus = (dateTimeStr) => {
    if (dateTimeStr) {
      const isUTC = dateTimeStr.endsWith("Z");

      if (!isUTC) {
        dateTimeStr = dateTimeStr + "Z";
      }
      const dateTime = new Date(dateTimeStr);
      const now = new Date();
      const utcNow = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds(),
        ),
      );

      const diffMs = utcNow - dateTime;
      if (diffMs <= 5 * 60 * 1000) {
        return "online";
      } else {
        return formatDateTime(dateTimeStr);
      }
    } else {
      return "offline";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "online":
        return "green";
      case "-":
        return "grey";
      default:
        return "red";
    }
  };
  const getStatusIndicator = (status) => (
    <Box
      sx={{
        display: "inline-block",
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: getStatusColor(status),
        marginRight: 1,
      }}
    />
  );

  let status = getLastSeenStatus(device.lastSeen);
  if (device?.isManuallyAddedMachine) {
    status = "online";
  }

  const upgradeDevice = (computerId) => {
    let getComputerUpgradeList;
    try {
      getComputerUpgradeList =
        JSON.parse(myLocalStorage.getItem("upgradeComputer")) || [];
    } catch (e) {
      getComputerUpgradeList = [];
    }

    if (!Array.isArray(getComputerUpgradeList)) {
      getComputerUpgradeList = [];
    }

    getComputerUpgradeList.push({
      computerId: computerId,
      time: new Date(),
    });

    myLocalStorage.setItem(
      "upgradeComputer",
      JSON.stringify(getComputerUpgradeList),
    );

    setUpgrading(true);
    upgradeMachine(computerId);
  };

  const checkforComputerUpgradeList = () => {
    let getComputerUpgradeList;
    try {
      getComputerUpgradeList =
        JSON.parse(myLocalStorage.getItem("upgradeComputer")) || [];
    } catch (e) {
      getComputerUpgradeList = [];
    }

    if (!Array.isArray(getComputerUpgradeList)) {
      getComputerUpgradeList = [];
    }
    const computer_item = getComputerUpgradeList.find(
      (computer) => computer.computerId === device.id,
    );

    if (computer_item) {
      if (device.agentUpgradeAvailable) {
        setUpgrading(true);
      } else {
        let filteredArray = getComputerUpgradeList.filter(
          (item) => item.computerId !== device.id,
        );
        myLocalStorage.setItem(
          "upgradeComputer",
          JSON.stringify(filteredArray),
        );
        setUpgrading(false);
      }
    } else {
      setUpgrading(false);
    }
  };
  const getUpgradeStatus = () => {
    let getComputerUpgradeList;
    try {
      getComputerUpgradeList =
        JSON.parse(myLocalStorage.getItem("upgradeComputer")) || [];
    } catch (e) {
      getComputerUpgradeList = [];
    }

    if (!Array.isArray(getComputerUpgradeList)) {
      getComputerUpgradeList = [];
    }
    const computer_item = getComputerUpgradeList.find(
      (computer) => computer.computerId === device.id,
    );
    let currentTime = new Date();
    let status = "Upgrading";
    if (computer_item) {
      if (device.agentUpgradeAvailable) {
        const differenceInMinutes =
          (currentTime - new Date(computer_item.time)) / (1000 * 60);

        if (differenceInMinutes >= 15 && differenceInMinutes <= 30) {
          status = "Slow network";
        } else if (differenceInMinutes > 30) {
          let filteredArray = getComputerUpgradeList.filter(
            (item) => item.computerId !== device.id,
          );
          myLocalStorage.setItem(
            "upgradeComputer",
            JSON.stringify(filteredArray),
          );
          setUpgrading(false);
          status = "Network issue";
        } else {
          status = "Upgrading";
        }
      }
    }
    return status;
  };

  useEffect(() => {
    checkforComputerUpgradeList();
  }, [device, setActiveComputer]);

  return (
    <Stack direction={"row"}>
      <ListItem
        sx={{
          minWidth: 300,
          wordBreak: "break-word",
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: "white" }}>
            {device?.isManuallyAddedMachine ? (
              <RiComputerLine size={35} color={"#233044"} />
            ) : (
              osIcon[os]
            )}
          </Avatar>
        </ListItemAvatar>
        <Stack spacing={1}>
          <ListItemText
            primary={
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "inherit",
                    fontSize: "15px",
                    padding: 0,
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={
                    role !== "TENANT_USER" || !device?.isManuallyAddedMachine
                      ? () => {
                          setActiveComputer(device);
                          navigate("deviceDetails", {
                            state: {
                              device: device,
                              selectedTenant: selectedTenant,
                            },
                          });
                        }
                      : null
                  }
                >
                  {device.dNSHostName}
                </Button>

                {role !== "TENANT_USER" && device.isGateway ? (
                  <Box>
                    <Tooltip title="Gateway">
                      <IconButton>
                        <RouterIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : null}
              </Stack>
            }
            secondary={
              <Stack spacing={1} component={"span"}>
                <Typography
                  component="span"
                  sx={{ fontSize: "14px", display: "inline" }}
                  color="text.secondary"
                >
                  {"OS: "}
                  {device.OperatingSystem}
                </Typography>
                {!device?.isManuallyAddedMachine ? (
                  <Typography
                    component="span"
                    sx={{ fontSize: "14px", display: "inline" }}
                    color="text.secondary"
                  >
                    {"Agent version: "}
                    {device.agentVersion}
                  </Typography>
                ) : null}
                {device?.installTag && device?.installTag !== null ? (
                  <Stack direction={"row"} spacing={1}>
                    <Typography
                      component="span"
                      sx={{ fontSize: "14px", display: "inline" }}
                      color="text.secondary"
                    >
                      {"Install Tag: "}
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {Array.isArray(device?.installTag) ? (
                        device?.installTag.map((tags, index) => (
                          <Typography key={index} sx={{ marginRight: 1 }}>
                            {tags}
                            {index < device?.installTag.length - 1 ? "," : ""}
                          </Typography>
                        ))
                      ) : (
                        <Typography sx={{ marginRight: 1 }}>
                          {device?.installTag}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                ) : null}

                {!upgrading ? (
                  <Typography
                    component="span"
                    sx={{ fontSize: "14px", display: "inline" }}
                    color="text.secondary"
                  >
                    {status !== "-" && getStatusIndicator(status)}

                    {status === "online" ? (
                      device?.isManuallyAddedMachine ? (
                        <span>{"Configured"}</span>
                      ) : (
                        <span>{"Online"}</span>
                      )
                    ) : status === "-" ? (
                      <>{"Status: -"}</>
                    ) : (
                      <Tooltip title={`${status}`}>
                        <span>{"Offline"}</span>
                      </Tooltip>
                    )}
                  </Typography>
                ) : (
                  <>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <RxUpdate />
                      <Typography
                        component="span"
                        sx={{ fontSize: "14px", display: "inline" }}
                        color="text.secondary"
                      >
                        <span>{getUpgradeStatus()}</span>
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            }
          />
        </Stack>
      </ListItem>
      {device.agentUpgradeAvailable && !upgrading && role !== "TENANT_USER" && (
        <Box>
          <Tooltip title="Upgrade">
            <IconButton>
              <GrUpgrade size={25} onClick={() => upgradeDevice(device.id)} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Stack>
  );
};

export default DevicelistItem;
