import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Stack,
  List,
  ListItem,
  Checkbox,
  Chip,
} from "@mui/material";

import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";
import ConnectionModal from "../pages/Endpoints/components/ConnectionModal";

const ResourceRequestForm = ({
  resourceName,
  resourcePath,
  selectedUser,
  resourceId,
  resourceType,
  computer,
  sessionRequest,
  tenantName,
  publisherId,
  isWhiteswanAccess,
  isFromEndPointApp = false,
  isFromEndPoint = false,
  handleConnectButton,
}) => {
  const [open, setOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [step, setStep] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCnUser, setSelectedCnUser] = useState(null);
  const userData = useUserStore((state) => state.user);
  const { role, email, userId, userRoleId, userRole } = useUserStore(
    (state) => state.user,
  );

  const [usersList, setUsersList] = useState([]);
  const [accessType, setAccessType] = useState("DYNAMIC");
  const [selectedTime, setSelectedTime] = useState("");
  const [users, setUsers] = useState([]);

  const isServer = computer?.belongedToUser
    ? computer.belongedToUser === userData.email
      ? false
      : true
    : true;

  const isDeviceSSh = (device) => {
    return (
      device.OperatingSystem.toLowerCase().includes("linux") ||
      device.OperatingSystem.toLowerCase().includes("macos")
    );
  };

  const handleOpen = () => {
    if (computer?.isManuallyAddedMachine) {
      setStep(2);
    } else {
      if (!isFromEndPoint) setStep(2);
      if (isFromEndPoint && !selectedCnUser) {
        setStep(1);
      }
      fetchAllUsers();
    }

    setOpen(true);
  };

  const handleClose = () => {
    setRequestMessage("");
    setSelectedTime("");
    setSelectedCnUser(null);
    setOpen(false);
  };

  const getStringBeforeAtSymbol = (str) => {
    const atIndex = str.indexOf("@");
    return atIndex !== -1 ? str.substring(0, atIndex) : str;
  };

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    if (value?.length > 3 || value.includes("e") || value === 0) return;

    if (regex.test(value)) {
      setSelectedTime(value);
    } else if (!value) setSelectedTime("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const handleSubmit = async (
    connectionlabel,
    connectionmode,
    connectionusername,
    selectedModeuserId,
    selectedTime,
  ) => {
    const username = getStringBeforeAtSymbol(userData.email);
    const data = {
      userId: sessionRequest ? userData.userId : selectedUser.id,
      username: sessionRequest ? username : selectedUser?.cn,
      email:
        isFromEndPointApp && isDeviceSSh(computer)
          ? userData.email
          : sessionRequest
            ? userData.email
            : selectedUser?.email,
      resourceType: sessionRequest ? "GROUP" : resourceType,
      resourceId,
      resourceName,
      computerName:
        isFromEndPointApp && isDeviceSSh(computer)
          ? sessionRequest
            ? userData.email
            : selectedUser?.email
          : computer?.dNSHostName,
      resourcePath: resourcePath || "",
      requestMessage,
      requestStatus: "PENDING",
      selectedTime: selectedTime,
      computerId: computer?.id,
      createdAt: new Date().toISOString(),
      privilegeLevel: accessType,
      tenantName,
      publisherId,
      userEmail: userData.email,
      toConnect: sessionRequest ? true : false,
      isWhiteswanAccess,
      isServer,
      machineUser: selectedCnUser?.cn,
      machineUserId: selectedCnUser?.id,
      connectionMode: isDeviceWindows(computer) ? connectionmode : "",
      connectionUser: isDeviceWindows(computer) ? connectionusername : "",
      connectionLabel: isDeviceWindows(computer) ? connectionlabel : "",
    };

    try {
      await axios.post(`${NGROK}/api/access/create`, data);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUserSelect = (cnuser) => {
    if (selectedCnUser?.cn === cnuser.cn) {
      setSelectedCnUser(null);
    } else {
      setSelectedCnUser(cnuser);
    }
  };

  const filterdCnUsers = usersList?.filter(
    (users) =>
      users.cn.toLowerCase().includes(searchQuery.toLowerCase()) &&
      users.cn.toLowerCase() !== "root" &&
      users.cn.toLowerCase() !== "nobody",
  );

  const prepareForSubmit = (
    mode,
    label,
    username,
    selectedModeuserId,
    selectedTime,
  ) => {
    handleSubmit(label, mode, username, selectedModeuserId, selectedTime);
  };

  const fetchAllUsers = async () => {
    if (isDeviceSSh(computer)) {
      await axios
        .get(`${NGROK}/api/${tenantName}/computer-users/${computer?.id}`)
        .then((res) => {
          if (res.data) {
            let content = res.data.content;
            if (
              userData.userRole === "epam-user" ||
              userData.userRole === "server-pam-user" ||
              userData.userRole === "developer-user" ||
              userData.userRole === "thirdparty-user"
            ) {
              content = res.data.content.filter(
                (data) => data.cn !== "wsadmin",
              );
            }

            setUsersList(content);
          }
        });
    } else {
      setStep(2);
    }
  };

  const isDeviceWindows = (device) => {
    if (device.OperatingSystem?.trim().length > 0) {
      return device.OperatingSystem.toLowerCase().includes("windows");
    } else {
      return true;
    }
  };

  const getAllAuthUsers = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${tenantName}/credentials?computerId=${computer?.id}`,
      );
      if (response?.data) {
        setUsers(response?.data);
      }
    } catch (e) {
      setUsers([]);
    }
  };

  useEffect(() => {
    getAllAuthUsers();
  }, [computer]);

  const connectionModal = useMemo(
    () =>
      sessionRequest && isDeviceWindows(computer) ? (
        <ConnectionModal
          computer={computer}
          open={open}
          onClose={handleClose}
          users={users}
          handleConnectButton={handleConnectButton}
          filterdCnUsers={filterdCnUsers}
          resourceName={resourceName}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          prepareForSubmit={prepareForSubmit}
        />
      ) : null,
    [open],
  );

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        <strong>
          {sessionRequest ? "Request connect access" : "Request access"}
        </strong>
      </Button>

      {sessionRequest && isDeviceWindows(computer) ? (
        connectionModal
      ) : (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ fontSize: "18px" }}>
            {step === 1 ? "Select User" : `Access request to ${resourceName}`}
          </DialogTitle>
          <DialogContent>
            {step === 1 ? (
              <>
                <TextField
                  label="Search users"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <List
                  sx={{
                    maxHeight: "450px",
                    overflowY: "auto",
                    marginTop: "16px",
                  }}
                >
                  {filterdCnUsers.length === 0 ? (
                    <>
                      <ListItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6" align="center">
                          <strong>No Users available</strong>
                        </Typography>
                      </ListItem>
                    </>
                  ) : (
                    filterdCnUsers.map((user, index) => (
                      <ListItem key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedCnUser?.cn === user?.cn}
                              onChange={() => handleUserSelect(user)}
                            />
                          }
                          label={user.cn}
                        />
                      </ListItem>
                    ))
                  )}
                </List>
              </>
            ) : (
              <>
                {isFromEndPoint && isDeviceSSh(computer) ? (
                  <Stack spacing={2} mt={3} mb={3}>
                    <Typography variant="h6">Machine User:</Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      {selectedCnUser && (
                        <Chip label={selectedCnUser?.cn} variant="outlined" />
                      )}
                    </Stack>
                  </Stack>
                ) : null}

                <Stack spacing={4}>
                  <FormControl component="fieldset">
                    <Typography variant="h6">Access Type:</Typography>
                    <RadioGroup
                      aria-label="access-type"
                      name="access-type"
                      value={accessType}
                      onChange={(e) => handleAccessType(e.target.value)}
                      row
                    >
                      <FormControlLabel
                        value="PERMANENT"
                        control={<Radio />}
                        label="Permanent"
                      />
                      <FormControlLabel
                        value="DYNAMIC"
                        control={<Radio />}
                        label="Timed"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Typography variant="h6">
                    Computer Name: {computer?.dNSHostName}
                  </Typography>

                  <Stack spacing={2} mt={3}>
                    <Typography variant="h6">Access justification:</Typography>
                    <TextField
                      maxRows={5}
                      minRows={5}
                      inputProps={{ maxLength: 150 }}
                      multiline
                      value={requestMessage}
                      onChange={(e) => setRequestMessage(e.target.value)}
                    />
                  </Stack>
                  {accessType === "DYNAMIC" ? (
                    <Stack spacing={2}>
                      <Typography variant="h6">Time:</Typography>
                      <TextField
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]+",
                        }}
                        onKeyPress={handleKeyPress}
                        value={selectedTime}
                        type="text"
                        fullWidth
                        size="small"
                        label={"Enter time in minutes"}
                        onChange={(e) => handleInputChange(e.target.value)}
                      />
                    </Stack>
                  ) : null}
                </Stack>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {step === 1 ? (
              <Button
                onClick={() => setStep(2)}
                color="primary"
                disabled={!selectedCnUser}
              >
                Next
              </Button>
            ) : (
              <>
                {isFromEndPoint && isDeviceSSh(computer) ? (
                  <Button onClick={() => setStep(1)} color="primary">
                    Back
                  </Button>
                ) : null}
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    handleSubmit(null, null, null, null, selectedTime)
                  }
                  disabled={accessType === "DYNAMIC" && !selectedTime}
                  color="primary"
                >
                  Submit
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ResourceRequestForm;
